// FACULTY
export const faculty = {
  title: "Faculty",
  description: "",
  members: [
    {
      name: "Kavita Bala",
      netId: "kb97",
      position:
        "Dean of Ann S. Bowers \n College of Computing and Information Science",
      bio:
        "Kavita Bala is a Professor in the Computer Science Department and Program of Computer Graphics at Cornell University. She received her S.M. and Ph.D. from the Massachusetts Institute of Technology (MIT), and her B.Tech. from the Indian Institute of Technology (IIT, Bombay). She co-founded GrokStyle, and serves as Chief Scientist (2015-), and is a faculty Fellow with the Atkinson Center. \n Bala's work is inter-disciplinary, spanning the areas of computer graphics, computer vision, human perception, and architecture. Her research has had significant industrial impact, including the core rendering engine in Autodesk's 360 cloud renderer, and GrokStyle's visual search engine. Bala's 3D Mandala reconstruction was installed at the Rubin Museum of Art in New York, and demonstrated to the Dalai Lama." +
        "\nBala is committed to increasing diversity in computer science. As the only woman in her undergraduate CS class, she appreciates the progress that has been made in the past decade, but there is more work to be done in academia and particularly in industry.",
    },
    {
      name: "Anne Bracy",
      netId: "awb93",
      position: "Senior Lecturer",
      bio: "I am a Senior Lecturer in the Computer Science department. I obtained a BS in Symbolic Systems and an MS in Computer Science from Stanford University and a PhD in Computer Science at the University of Pennsylvania. I teach primarily undergraduate systems courses. Prior to my arrival at Cornell, I was a Principal Lecturer at Washington University in St. Louis. I was also a Research Scientist at the Microarchitecture Research Lab at Intel Labs in Santa Clara, California. In addition to being a member of WICC's Faculty Board I am also a 2016 participant of the Faculty Institute for Diversity. \nI support diversity in technology because I love computer science. Everyone should have an equal opportunity to participate fully in this stimulating field. Furthermore, a wider range of perspectives and backgrounds naturally yields more innovative ideas and wider applications. Increasing the diversity of computer scientists improves computer science.",
    },
    {
      name: "Eva Tardos",
      netId: "et28",
      position: "Professor",
      bio: "Eva Tardos is the Jacob Gould Schurman Professor of Computer Science and Information Science at Cornell. Her research focuses on Algorithm Design and the interface of Computing and Economics, with an emphasis on algorithms and games on networks. She is a member of the National Academy of Engineering and National Academy of Sciences. Tardos received her PhD in from Eötvös University, Budapest, Hungary. She did a series of postdocs and short term positions in computer science including time spent at Berkeley and MIT, and has been a faculty member at Cornell since 1989. \nShe has been the faculty advisor for the Women in Computing at Cornell since its inception, and though WICC's helped encourage many more women to choose CS major. She earned her PhD in 1984, the year that had most women in computing (see for example, the charts in this article for trends in percentage of women in computing). With the help of WICC, the percentage of women in CS at Cornell grew to currently over 25% from barely 10% not so many years ago. She is very proud of WICC for all the group has done to make CS a better major for women, and committed to continue this work, hoping to bring the percentage of women in CS at Cornell up to at least what it was nationally in the early eighties.",
    },
    {
      name: "Karen Levy",
      netId: "kl838",
      position: "Assistant Professor",
      bio: "Karen Levy is an assistant professor in the Department of Information Science at Cornell University and associated faculty at Cornell Law School. She researches the social, legal, and ethical implications of new technologies, with particular focus on inequality, labor, and intimacy. She holds a Ph.D. in Sociology from Princeton University and a J.D. from Indiana University Maurer School of Law. She is currently at work on a book tracing the emergence of monitoring technologies in the United States long-haul trucking industries. \nI am committed to supporting diversity in CIS because we can't find solutions to the social or technical problems we work on without the benefit of multiple perspectives and lived experiences. Computing touches every part of life and every social institution, and it's key that we have diverse voices represented to ensure that new technology is developed and used in ways that address the entrenched histories of inequality and injustice in our society.I am committed to opening up space for all students to participate fully in CIS disciplines, and feel a strong personal commitment to ensuring that ours is a community in which each person's expertise and experiences are valued.",
    },
  ],
};

//PRESIDENTS
export const presidents = {
  title: "Presidents",
  description: " ",
  members: [
    {
      name: "Jasmine Samadi",
      position: "Co-President",
      netId: "jns222",
      bio: "WICC is the first place that made me feel like I belong in the computing field. After years of trying to find my path and being discouraged, I finally found a welcoming and supportive community of women and non-binary people in tech. I am so grateful for the warmth and guidance given to me from WICC members. I can only hope to extend a similar arm and welcome more people whose shoes I was in. ",
    },
    {
      name: "Neha Sunkara",
      position: "Co-President",
      netId: "ns597",
      bio: " ",
    },
  ],
};

//OPERATIONS
export const operations = {
  title: "Operations",
  description: " ",
  members: [
    {
      name: "Gaby Castro",
      position: "Co-Treasurer",
      netId: "gc422",
      bio: " ",
    },
    {
      name: "Razika Rahman",
      position: "Co-Treasurer",
      netId: "rr772",
      bio: " ",
    },
    {
      name: "Chloe Chan",
      position: "Secretary",
      netId: "cac499",
      bio: " ",
    },
  ],
};

//CORPORATE
export const corporate = {
  title: "Corporate",
  description: " ",
  members: [
    {
      name: "Hermione Bossolina",
      position: "Vice President, Corporate Relations",
      netId: "hlb88",
      bio: "An athlete who loves Data, Math and History. Fiction and Imagination for the future. Influence the youth. Impact the community.",
    },
    {
      name: "Vicki Yang",
      position: "Corporate Relations Co-Director",
      netId: "vzy3",
      bio: " ",
    },
    {
      name: "Meg Isohata",
      position: "Corporate Relations Co-Director",
      netId: "msi32",
      bio: " ",
    },
    {
      name: "Rahma Abdullah",
      position: "Corporate Relations Co-Director",
      netId: "ra567",
      bio: "",
    },
    {
      name: "Reva Jariwala",
      position: "Alumni Relations Co-Director",
      netId: "raj97",
      bio: " ",
    },
    {
      name: "Diya Bansal",
      position: "Alumni Relations Co-Director",
      netId: "db688",
      bio: "I am an undergraduate junior student in the College of Engineering at Cornell University pursuing a Bachelor of Science degree in Computer Science with a minor in Information Science (Data Science track).",
    },
  ],
};

//PHOTO & FILM
export const photo = {
  title: "Photo & Film",
  description: " ",
  members: [
    {
      name: "Zhalae Daneshvari",
      position: "Photographer Director",
      netId: "zwd3",
      bio: "Zhalae is a freshman at Cornell studying Info Sci in the College of Arts and Sciences. As part of the P+F committee, she loves to capture the welcoming nature of WICC’s community at events. In her free time she likes listening to music and her favorite artists are Taylor Swift and The Neighbourhood.",
    },
    {
      name: "Afsheen Alvi",
      position: "Photographer",
      netId: "aa2293",
      bio: "Sophomore from Lucknow, India. Outside of academics, interested in baking, greek mythology, space law and exploration, travel and music",
    },
    {
      name: "Amy Chitnumsab",
      position: "Photographer",
      netId: "ac2295",
      bio: "Amy is currently pursuing a career in Data Science with her Statistics major. Other than being part of the WICC Photo and Film Committee, she is also part of Cornell Data Journal (CDJ) and Cornell Innovative Film Tech Club (CIFT). ",
    },
    {
      name: "Rachael Close",
      position: "Photographer",
      netId: "rlc355",
      bio: "enthusiastic photographer, also likes to crochet, figure skate, and play tennis",
    },
    {
      name: "Sharafa Mohammed",
      position: "Photographer",
      netId: "sm2592",
      bio: "Sharafa has been on the Photo & Film committee since Fall 2023. She is passionate about the world of machine learning and the application of computing in healthcare. Sharafa has enjoyed attending events and engaging with the WICC community, and she hopes to continue executing campaigns and contributing to events over the following semester.",
    },
  ],
};

//TECHNICAL
export const technical = {
  title: "Technical",
  description: " ",
  members: [
    {
      name: "Uyen Hoang",
      position: "Technical Director",
      netId: "umh4",
      bio: "Uyen is a cat and panda lover who passionate about Design, Tech, and the Written Word",
    },
    {
      name: "Laura Wu",
      position: "Head Designer",
      netId: "sw2262",
      bio: "Laura is a junior in the College of Arts and Sciences studying Information Science. She enjoys listening to K-pop in her free time.",
    },
    {
      name: "Abigail Joseph",
      netId: "amj92",
      position: "Developer",
      bio: "Abigail is a freshman from Northern Virginia majoring in Computer Science in the College of Engineering. She enjoys karaoke, exploring new cuisines, and traveling.",
    },
    {
      name: "Alina Yuan",
      netId: "ay332",
      position: "Developer",
      bio: "Alina is a sophomore from Santa Clara, California majoring in Computer Science with a minor in psychology. She loves her Shiba Inu and making digital art, and also the Technical Committee!",
    },
    {
      name: "Amy Sharin",
      netId: "ars369",
      position: "Developer",
      bio: "Amy is a junior from Long Island majoring in Computer Science. She loves hanging out with friends, baking, and basketball.",
    },
    {
      name: "Carolina Herrera",
      netId: "ch742",
      position: "Developer",
      bio: "Carolina is a senior in the College of Arts and Science studying Information Science. She loves to wrap herself inside a tortilla blanket at the end of each day",
    },
    {
      name: "Dora Weng",
      netId: "cw749",
      position: "Developer",
      bio: "Dora is a sophomore in the College of engineering studying Computer Science. She has an unhealthy obsession for coffee and you can find her working at Cafe Jennie!",
    },
    {
      name: "JJ Bai",
      netId: "jhb342",
      position: "Developer",
      bio: "JJ is a Junior born in Beijing and went to high school in NYC. She is majoring in Computer Science in College of Engineering and minoring in Philosophy. She likes hanging out with her friends, and read about random interesting topics, feel free to reach out to her for a casual chat!",
    },
    {
      name: "Jessica Andrews",
      netId: "jaa375",
      position: "Designer",
      bio: "Jessica is a Sophmore from Upstate NY, She is majoring in Information Science in the School of Engineering with a concentration in Interactive Technology and UX. In her free time she likes to play tennis, bake, and watch shows!",
    },
    {
      name: "Jessica Liu",
      netId: "jl4229",
      position: "Designer",
      bio: "Jessica is a freshmam from Brooklyn majoring in information science (ux concentration).  She enjoys watching/taking pics of sunset, traveling and making avcado toasts!",
    },
    {
      name: "Katherine Huang",
      netId: "kh784",
      position: "Developer",
      bio: "Katherine is a freshman from Connecticut majoring in Information Science with a concentration in UX Design in CALS. She enjoys crocheting, singing, thrifting, and drinking matcha!",
    },
    {
      name: "Kelly Tran",
      netId: "kt457",
      position: "Developer",
      bio: "Kelly is a junior from the Greater Philadelphia area. She is in the College of Engineering studying computer science. She enjoys crocheting, eating good food, and meeting new people. Feel free to reach out to her for a quick coffee chat!",
    },
    {
      name: "Maria Sandee Basco",
      netId: "myb22",
      position: "Developer",
      bio: "Sandee is a sophomore from Queens majoring in Computer Science with a Business minor. She loves thrifting and matcha lattes.",
    },
    {
      name: "Omisha Sharma",
      netId: "os226",
      position: "Developer",
      bio: "Omisha is a sophomore from Plainsboro, NJ in the College of Engineering student CS. She loves F1, playing piano, and hiking in Ithaca!",
    },
    {
      name: "Ruoxin Chen",
      netId: "rc753",
      position: "Developer",
      bio: "Felicia is a sophomore majoring in computer science in Arts & Sciences and minoring (possibly majoring) in music.",
    },
    {
      name: "Selena Liu",
      netId: "sl2663",
      position: "Developer",
      bio: "Selena is a sophomore from New Jersey majoring in Computer Science in the College of Arts and Sciences. She enjoys eating good food, watching movies, and exploring Cornell and Ithaca!",
    },
    {
      name: "Shirley Yuan",
      netId: "sy378",
      position: "Designer",
      bio: "Shirley is a junior from Western Massachusetts majoring in Information Science and studying UX. She loves playing the chimes at Cornell, climbing rope courses, and spending all her money at pokelava!",
    },
    {
      name: "Shriya Sudhakar",
      netId: "ss3576",
      position: "Developer",
      bio: "Shriya is a freshman in the College of Engineering majoring in Computer Science and minoring in Applied Math. She loves playing the violin, trying new puzzles, and hanging out with friends. She's super excited for an amazing year on Technical Committee!",
    },
    {
      name: "Sneha Rajaraman",
      netId: "sr934",
      position: "Developer",
      bio: "Sneha is a sophomore from Northern Virginia in the College of Engineering majoring in Computer Science. She enjoys calligraphy, singing, dancing (pretty much anything music replated), and baking!",
    },
    {
      name: "Sophia Wang",
      netId: "sw895",
      position: "Developer",
      bio: "Sophia is a sophomore from Colorado majoring in Computer Science in the College of Engineering. She loves to read, ski, and explore new places!",
    },
    {
      name: "Yuhan Sun",
      netId: "ys728",
      position: "Developer",
      bio: "Yuhan is a sophomore from Nanjing, China studying Computer Science in the College of Arts and Science. She is a huge fan of Zhou Shen and anime!",
    },
    {
      name: "Zhalae Daneshvari",
      netId: "zwd3",
      position: "Developer",
      bio: "Zhalae is a freshman from New Jersey studying Information Science in the College of Arts & Sciences. She enjoys photography, drawing, and eating food at new places!",
    },
  ],
};

//ACADEMIC
export const academic = {
  title: "Academic",
  description: " ",
  members: [
    {
      name: "Lauren Chan",
      position: "Vice President, Academic",
      netId: "lac348",
      bio: "Lauren is a junior from Princeton, NJ majoring in Information Science with minors in Business and Nutritional Sciences. This past summer, Lauren worked as a Digital Marketing Analytics Intern at Tarte Cosmetics. She first joined WICC in Fall 2022 under WICC's mentorship program as an ILR major looking to get more into the tech space and later joined board the following semester as Underclassmen Outreach Co-Director. After switching majors, she is now Vice President of the Academic Team for this semester. She has experience being an external and internal transfer and is involved in a business fraternity on campus along with Grub Ventures, a food and agriculture tech-focused venture capital club. In her free time, Lauren enjoys staying active, exploring national parks, trying out new recipes, and listening to true crime podcasts.",
    },
    {
      name: "Helen Bian",
      position: "Faculty Relations Co-Director",
      netId: "hjb94",
      bio: " ",
    },
    {
      name: "Victoria Yu",
      position: "Faculty Relations Co-Director",
      netId: "vwy5",
      bio: " ",
    },
    {
      name: "Arshia Agrawal",
      position: "Career Development Co-Director",
      netId: "aa2443",
      bio: " ",
    },
    {
      name: "Alina Yuan",
      position: "Career Development Co-Director",
      netId: "ay332",
      bio: " ",
    },
    {
      name: "Sarah Young",
      position: "Underclassmen Outreach Co-Director",
      netId: "sy398",
      bio: " ",
    },
    {
      name: "Selena Liu",
      position: "Underclassmen Outreach Co-Director",
      netId: "sl2663",
      bio: " ",
    },
  ],
};

//BRAND
export const brand = {
  title: "Brand",
  description: " ",
  members: [
    {
      name: "Victoria Whittle",
      position: "Vice President, Brand",
      netId: "vnw3",
      bio: " ",
    },
    {
      name: "Uyen Hoang",
      position: "Technical Director",
      netId: "umh4",
      bio: "Uyen is a cat and panda lover who passionate about Design, Tech, and the Written Word",
    },
    {
      name: "Selin Toker",
      position: "Publicity Director",
      netId: "st798",
      bio: "I'm studying Computer Science in the College of Engineering, and I come from Istanbul, Turkey. Since joining WICC, I've connected with some really kind people and collaborated with others who share my interests about computing. As the publicity director, I enjoy spreading the word about WICC, which is all about creating a supportive community that brings together people from diverse backgrounds. Outside of all this, I enjoy reading, listening to music, watching romcoms, and taking lots of photos in my free time!",
    },
    {
      name: "Zhalae Daneshvari",
      position: "Photography Director",
      netId: "zwd3",
      bio: " ",
    },
    {
      name: "Lauren Jun",
      position: "Design Co-Director",
      netId: "lj295",
      bio: " ",
    },
    {
      name: "Goretti Muriithi",
      position: "Design Co-Director",
      netId: "gm552",
      bio: " ",
    },
  ],
};

//OUTREACH
export const outreach = {
  title: "Outreach",
  description: " ",
  members: [
    {
      name: "Jennifer Ai",
      position: "Vice President, Outreach",
      netId: "jla292",
      bio: "I joined WICC because I wanted to be a part of a supportive group of people within the computing and information science community. I am so glad to be a part of the outreach team because I have had the pleasure of working with so many wonderful peers and teaching younger students who are interested in computing. ",
    },
    {
      name: "Miriam Alex",
      position: "Outreach Co-Director",
      netId: "mta63",
      bio: "When I look back on my first semester, it’s the small, warm moments that seem to peek out from my memory. When I first arrived at Cornell, I sorely lacked a community; like many others, I wasn’t sure who I could spend time with, how to manage a quickly intensifying schedule, or what the correct steps to take moving forward could be. However, in the beginning of my first year, I remember a friend dragging me along to Phillips Hall, promising Insomnia cookies if we could sit through a meeting. Coincidentally, it was an interest meeting for the WICC mentorship. After meeting the mentors, we were struck by how vibrant and motivated everyone was; it felt like the beginning of something larger than ourselves. To put it simply, WICC inspired me to do more and provided me with the resources to do it. Through my time as an outreach co-director, I hope that I can provide a fraction of that community to Cornell and greater Ithaca. ",
    },
    {
      name: "Jeanie Chan",
      position: "Outreach Co-Director",
      netId: "jc2885",
      bio: "Hi! I'm Jeanie, a CS major in the College of Engineering. I joined WICC as a freshman and have gained a lot from joining programs and attending events. WICC is such a supportive and welcoming community :) ",
    },
    {
      name: "Isabella Wells",
      position: "Outreach Co-Director",
      netId: "imw25",
      bio: " ",
    },
  ],
};

//COMMUNITY
export const community = {
  title: "Community",
  description: " ",
  members: [
    {
      name: "Victoria Varkonyi",
      position: "Vice President, Community",
      netId: "vv229",
      bio: " ",
    },
    {
      name: "Claire Tan",
      position: "Social Co-Director",
      netId: "ct556",
      bio: "When I arrived at Cornell, the world of computing was like a distant realm to me and things like joining clubs, finding opportunities, and choosing classes made me feel lost. WICC has WICC has allowed me to join a welcoming community of driven, passionate individuals who have helped me in my journey. The camaraderie of this community has made the complex seem approachable and the unknown less daunting. I’m excited to continue forging new connections in WICC and further exploring the exciting opportunities this community offers!",
    },
    {
      name: "Krishna Patel",
      position: "Social Co-Director",
      netId: "khp42",
      bio: "I joined WICC as a freshman fall semester. Cornell is a huge university, but WICC provided the supportive and caring community that I wanted! WICC allowed me to meet people with similar interests, get advice and assistance from upperclassmen, and opened me up to a lot of amazing opportunities on campus!",
    },
    {
      name: "Sreya Jonnalagadda",
      position: "Mentorship Co-Director",
      netId: "sj652",
      bio: "Attending a STEM high school where I was often the one of the few girls in the classes, I often felt unheard and unconfident. I knew coming into college that I wanted to join a community supporting women as they navigate the tech space and WICC was just that! I look forward to contributing to this empowering and inclusive community and watching it blossom. In my free time I love to rock climb!",
    },
    {
      name: "Sophie Wang",
      position: "Mentorship Co-Director",
      netId: "slw284",
      bio: " ",
    },
    {
      name: "Tanisha Kore",
      position: "Allyship Co-Director",
      netId: "tk494",
      bio: " ",
    },
    {
      name: "Jarin Rahman",
      position: "Allyship Co-Director",
      netId: "jsr296",
      bio: "Jamming to k-pop, blowing bubbles, making each other beaded bracelets – all while laughing and exchanging amusing stories about our lives so far – wasn't something I expected to happen at WICC Away. I didn’t think I’d become entangled in the lives of my newly-met peers and share our wildest dreams and fears. Being in a community of people who were similar to me reminded me of how in most male dominated spaces, it's hard to reach out for help and feel included because of prejudice and judgements. I wanted to become a part of the WICC community that promotes inclusiveness and makes those new to the computing field feel safe. ",
    },
  ],
};

//ADVISORS
export const advisors = {
  title: "Advisors",
  description: " ",
  members: [
    {
      year: "2025",
      position: "Advisor",
      netId: "jg2368",
      name: "Jennifer Gu",
      major: " ",
      bio: " ",
    },
    {
      year: "2025",
      position: "Advisor",
      netId: "jgp88",
      name: "Julia Papp",
      major: " ",
      bio: " ",
    },
    {
      year: "2025",
      position: "Advisor",
      netId: "ml953",
      name: "Mikayla Lin",
      major: " ",
      bio: " ",
    },
    {
      year: "2025",
      position: "Advisor, Mentorship",
      netId: "tl559",
      name: "Tiffany Lee",
      major: "Information Science",
      bio: " ",
    },
    {
      year: "2025",
      position: "Advisor, Social",
      netId: "aua6",
      name: "Amida Akpan",
      major: "Computer Science",
      bio: " ",
    },
    {
      year: "2024",
      position: "Advisor, Design",
      netId: "sag292",
      name: "Stephanie Ginting",
      major: " ",
      bio: " ",
    },
    {
      year: "2024",
      position: "Advisor",
      netId: "si223",
      name: "Shreeya Indap",
      major: " ",
      bio: "",
    },
    {
      year: "2024",
      position: "Advisor",
      netId: "nm458",
      name: "Neha Malepati",
      major: " ",
      bio: " ",
    },
    {
      year: "2024",
      position: "Advisor",
      netId: "khc74",
      name: "Kelly Chan",
      major: "Cognitive Science & Psychology",
      bio: " ",
    },
    {
      year: "2024",
      position: "Advisor, Outreach",
      netId: "as2759",
      name: "Ananya Seelam",
      major: " ",
      bio: "I became interested in computer science when I took my high school's Introduction to Computer Science course. I've loved math and logic ever since I can remember and computer science was a way I could apply those interests to solve real world problems. Coming to Cornell, I knew that I wanted to share my passion for computer science with other high school students who may not have had the same exposure to it that I've had. This led me to becoming a volunteer for the Girls Who Code program that WICC hosts for local middle and high school students. Now, as an Outreach Director, I help run this program which cultivates an inclusive and supportive environment for students to explore their interests in computer science!",
    },
    {
      year: "2024",
      position: "Advisor, Secretary/Alumni/Faculty Relations",
      netId: "rl582",
      name: "Ruike Liang",
      major: " ",
      bio: " ",
    },
    {
      year: "2024",
      position: "Advisor, VP Academic/Corporate Relations/Faculty Relations",
      netId: "as2585",
      name: "Aarushi Singh",
      major: "Computer Science",
      bio: " ",
    },
    {
      year: "2024",
      position: "Advisor, Co-President",
      netId: "to98",
      name: "Tina Ou",
      major: "Computer Science",
      bio: " ",
    },
    {
      year: "2023",
      position: "Advisor, VP Corporate, Alumni Relations, Corporate Relations",
      netId: "rh635",
      name: "Rejoice Hu",
      major: " ",
      bio: "Before coming into college, I had no exposure to computer science. When I took my first programming course in freshman year, I remember being blown away by how powerfully versatile CS is and how it placed the power to make an impact, however big or small, right at my fingertips. But given my interests in medicine / healthcare, I struggled for a long time to reconcile these two amazing but seemingly very different worlds, until I found Information Science, a major that added that human element I always wanted in technology! As a student new to CIS as well as Cornell during the pandemic, WICC’s strong community of women helped me feel included and empowered—I felt like I belonged and had people I could connect with. Seeing how much WICC has helped me grow and how WICC was consistently behind many of the most impactful events of my new Cornell experience, I am so grateful for this opportunity to pay it forward and create that same impact on my fellow Cornellians and beyond!",
    },
  ],
};
